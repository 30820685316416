<template>
  <div id="app">
    <div class="notice-bar-box-zhez" v-if="isZhez"></div>
    <!-- <NoticeBar :notices="list" @handleZhez="zhezClick"/> -->
    <router-view>
    </router-view>
    <!-- <NoticeBarDetail v-if="isZhez" @handleCloseClick="closeClick"/> -->
  </div>
</template>


<script>
// import NoticeBar from './components/NoticeBar.vue';  
// import NoticeBarDetail from './components/NoticeBarDetail.vue';  
export default {
  name: "App",
  components: {  
    // NoticeBar,
    // NoticeBarDetail  
  },
  data() {
    return {
      isZhez: false, // 控制����层是否显示
      list:['成都汉康信息产业有限公司与汉康信息技术集团有限公司为完全独立的法人实体，两家公司之间不存在任何股权或从属等关联关系。我们在此强调，任何有关汉康信息技术集团有限公司的商业行为、财务状况及法律责任均与成都汉康信息产业有限公司无关。请广大客户及合作伙伴注意，任何有关两家公司的信息，需分别对待和验证，以免造成不必要的误解和损失。我们将保留对编辑或传播误导性信息的行为采取法律措施的权利。']
    }
  },
  created() {
    if (this._isMobile()) {
      console.log("手机端");
    } else {
      console.log("pc端");
      // window.location.href = "http://192.168.70.11:8081/";
    }
  },
  methods: {
    closeClick(){
      this.$bus.$emit('closeClick', false)
    },
    zhezClick(val){
      this.isZhez = val;
    },
    // 判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/css/index.scss";
@import "@/assets/css/el.scss";

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 16px;
  width: 100%;
  height: 100%;
  font-family: Alibaba PuHuiTi;
  // font-family: Alibaba PuHuiTi;

}

html,
body {
  width: 100%;
  height: 100%;
  //background-color: black;
  overflow-x: hidden;
  position: relative;
  z-index: -100;
  font-size: 16px;
  // background-color: black;
  font-family: Alibaba PuHuiTi;

  div {
    box-sizing: border-box;
    // overflow: hidden;
  }
}

// html {
//   font-size: 19.2px; /*默认以设计稿为基准*/
// }

// @media only screen and (max-width: 1366px) {
//   html {
//     font-size: 13.66px;
//   }
// }
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) {
//     //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }
* {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  // color: #fff;
}

// @media (min-width: 1024px) {
//   body {
//     font-size: 18px;
//   }
// } /*>=1024的设备*/

// @media (min-width: 1100px) {
//   body {
//     font-size: 20px;
//   }
// } /*>=1100的设备*/
// @media (min-width: 1280px) {
//   body {
//     font-size: 22px;
//   }
// } /*>=1280的设备*/

// @media (min-width: 1366px) {
//   body {
//     font-size: 24px;
//   }
// }

// @media (min-width: 1440px) {
//   body {
//     font-size: 25px !important;
//   }
// }

// @media (min-width: 1680px) {
//   body {
//     font-size: 28px;
//   }
// }
// @media (min-width: 1920px) {
//   body {
//     font-size: 33px;
//   }
// }
.notice-bar-box-zhez{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.44);
    z-index: 101;
  }
</style>
