import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index/Index.vue'),
    meta: {
      name: 'index'
    },
  },
  {
    path: '/solutionCenter',
    name: 'solutionCenter',
    component: () => import('../views/SolutionCenter/index.vue'),
    meta: {
      name: 'solutionCenter'
    },
    children: [{
        path: '/', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: 'shouye',
        component: () => import('../views/SolutionCenter/shouye.vue'),
        meta: {
          name: 'solutionCenter'
        },
      },
      {
        path: 'shuiwu',
        name: 'shuiwu',
        component: () => import('../views/SolutionCenter/components/shuiwu.vue'),
        meta: {
          name: 'solutionCenter'
        },
        children: [{
            path: '/', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuishuiwu',
            component: () => import('../views/SolutionCenter/components/components/zhihuishuiwu.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuifangxun', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuifangxun',
            component: () => import('../views/SolutionCenter/components/components/fangxun.vue'),
            meta: {
              name: 'solutionCenter'
            },
          }, {
            path: 'zhihuigongshui', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuigongshui',
            component: () => import('../views/SolutionCenter/components/components/gongshui.vue'),
            meta: {
              name: 'solutionCenter'
            },
          }, {
            path: 'zhihuipaishui', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuipaishui',
            component: () => import('../views/SolutionCenter/components/components/paishui.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuihehu', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuihehu',
            component: () => import('../views/SolutionCenter/components/components/hehu.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
        ]
      },
      {
        path: 'zhengwu',
        name: 'zhengwu',
        component: () => import('../views/SolutionCenter/components/zhengwu.vue'),
        meta: {
          name: 'solutionCenter'
        },
      },
      {
        path: 'chengshi',
        name: 'chengshi',
        component: () => import('../views/SolutionCenter/components/chengshi.vue'),
        meta: {
          name: 'solutionCenter'
        },

        children: [{
            path: '/', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuichengshi',
            component: () => import('../views/SolutionCenter/components/components/zhihuichengshi.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'chengshidanao', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'chengshidanao',
            component: () => import('../views/SolutionCenter/components/components/chengshidanao.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuijiaotong', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuijiaotong',
            component: () => import('../views/SolutionCenter/components/components/zhihuijiaotong.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuiwenlv', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuiwenlv',
            component: () => import('../views/SolutionCenter/components/components/zhihuiwenlv.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuihuanbao', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuihuanbao',
            component: () => import('../views/SolutionCenter/components/components/zhihuihuanbao.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuijiaoyu', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuijiaoyu',
            component: () => import('../views/SolutionCenter/components/components/zhihuijiaoyu.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },
          {
            path: 'zhihuiyuanqu', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
            name: 'zhihuiyuanqu',
            component: () => import('../views/SolutionCenter/components/components/zhihuiyuanqu.vue'),
            meta: {
              name: 'solutionCenter'
            },
          },


        ]
      },
      {
        path: 'anquan',
        name: 'anquan',
        component: () => import('../views/SolutionCenter/components/anquan.vue'),
        meta: {
          name: 'solutionCenter'
        },
      }
    ]
  },
  {
    path: '/serviceCenter',
    name: 'serviceCenter',
    component: () => import('../views/serviceCenter/index.vue'),
    meta: {
      name: 'serviceCenter'
    },
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: () => import('../views/productCenter/index.vue'),
    meta: {
      name: 'productCenter'
    },
  },
  {
    path: '/pressCenter',
    name: 'pressCenter',
    component: () => import('../views/pressCenter/index.vue'),
    meta: {
      name: 'pressCenter'
    },
    children: [{
        path: '/', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: 'one',
        component: () => import('../views/pressCenter/components/one.vue'),
        meta: {
          name: 'pressCenter'
        },
      },
      {
        path: 'two',
        name: 'two',
        component: () => import('../views/pressCenter/components/two.vue'),
        meta: {
          name: 'pressCenter'
        },

      },
    ]
  },
  {
    path: '/marketingCenter',
    name: 'marketingCenter',
    component: () => import('../views/marketingCenter/index.vue'),
    meta: {
      name: 'marketingCenter'
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue'),
    meta: {
      name: 'about'
    },
    children: [{
        path: '/', //以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
        name: 'chengduhk',
        component: () => import('../views/about/hk.vue'),
        meta: {
          name: 'about'
        },
    },
      // 关于——汉康集团介绍路由页
      {
        path: 'jituanhk',
        name: 'jituanhk',
        component: () => import('../views/about/jituan.vue'),
        meta: {
          name: 'about'
        },
      },
      {
        path: 'zhuanjian',
        name: 'zhuanjian',
        component: () => import('../views/about/components/Team.vue'),
        meta: {
          name: 'about'
        },
      },
      {
        path: 'song',
        name: 'song',
        component: () => import('../views/about/components/Song.vue'),
        meta: {
          name: 'about'
        },
      },
      {
        path: 'wenhua',
        name: 'wenhua',
        component: () => import('../views/about/wenhua.vue'),
        meta: {
          name: 'about'
        },
      },
    ]
  },
  //专家团队  
  // {
  //   path: '/team',
  //   name: 'team',
  //   component: () => import('../views/about/components/Team.vue')
  // },
  //汉康集团
  // {
  //   path: '/jituan',
  //   name: 'jituan',
  //   component: () => import('../views/about/jituan.vue')
  // },
  //成功案例
  {
    path: '/chenggong',
    name: 'chenggong',
    component: () => import('../views/chenggong/index.vue'),
    meta: {
      name: 'chenggong'
    },
  },
  {
    path: '/xiangqing',
    name: 'xiangqing',
    component: () => import('../views/chenggong/xiangqing.vue'),
    meta: {
      name: 'chenggong'
    },
  },

  //  产品列表开始
  {
    path: '/shuiwu',
    name: 'cpshuiwu',
    component: () => import('../views/productCenter/components/shuiwu.vue'),
    meta: {
      name: 'productCenter'
    },
  },
  {
    path: '/chengshianquan',
    name: 'chengshianquan',
    component: () => import('../views/productCenter/components/anquan.vue'),
    meta: {
      name: 'productCenter'
    },
  },
  {
    path: '/zhihui',
    name: 'zhihui',
    component: () => import('../views/productCenter/components/zhihui.vue'),
    meta: {
      name: 'productCenter'
    },
  },
  {
    path: '/shebei',
    name: 'shebei',
    component: () => import('../views/productCenter/components/shebei.vue'),
    meta: {
      name: 'productCenter'
    },
  },
  {
    path: '/gongju',
    name: 'gongju',
    component: () => import('../views/productCenter/components/gongju.vue'),
    meta: {
      name: 'productCenter'
    },
  },
  //产品列表结束


  {
    path: '*',
    name: 'notfount',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router