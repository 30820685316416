/*
 * @Author: gengxiaoyu 2881768440@qq.com
 * @Date: 2024-08-05 09:30:13
 * @LastEditors: gengxiaoyu 2881768440@qq.com
 * @LastEditTime: 2024-08-05 13:58:48
 * @FilePath: \hk-official-website\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'lib-flexible'

import Header from './components/Header.vue'

import '@/assets/font/font.css';
Vue.prototype.$bus = new Vue();



Vue.use(ElementUI);
// Vue.config.productionTip = false

Vue.component('Header', Header)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')